const GoalHouse = ({ className = "" }) => {
  return (
    <svg
      width="64"
      height="40"
      viewBox="0 0 64 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.9482 32.3453C13.9482 33.1666 13.234 33.8323 12.353 33.8323C11.472 33.8323 10.7578 33.1666 10.7578 32.3453C10.7578 31.5241 11.472 27.6396 12.353 27.6396C13.234 27.6396 13.9482 31.5241 13.9482 32.3453Z"
        fill="#018B46"
      />
      <path
        d="M10.758 32.3453C10.758 33.1666 10.0438 33.8323 9.16281 33.8323C8.28182 33.8323 7.56763 33.1666 7.56763 32.3453C7.56763 31.5241 8.28182 27.6396 9.16281 27.6396C10.0438 27.6396 10.758 31.5241 10.758 32.3453Z"
        fill="#018B46"
      />
      <path
        d="M18.5737 4H26.2825V33.8313H13.7209V16.918L18.5737 4Z"
        fill="#FFF6BF"
      />
      <rect
        x="17.4304"
        y="21.0464"
        width="5.14337"
        height="12.7849"
        fill="#018B46"
      />
      <path
        d="M44.8493 4H26.2827V33.8313H50.2874V16.918L44.8493 4Z"
        fill="#F5E890"
      />
      <rect x="4" y="33.8311" width="56" height="1.06541" fill="#704F00" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16.7847V21.0463H36.5719L32 16.7847ZM36.5728 21.0465H32.0009L32.0009 25.3081L36.5728 21.0465Z"
        fill="#FFBD3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1433 16.7847V21.0463H45.7152L41.1433 16.7847ZM45.7161 21.0465H41.1442L41.1442 25.3081L45.7161 21.0465Z"
        fill="#FFBD3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 25.3081V29.5697H32.0009L32.0009 33.8311L36.5728 29.5695H36.5717L32 25.3081Z"
        fill="#FFBD3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1433 25.3081V29.5697H41.1442L41.1442 33.8311L45.7161 29.5695H45.715L41.1433 25.3081Z"
        fill="#FFBD3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.571 21.0464V25.308H36.572L36.572 29.5696L41.1438 25.308H41.1429L36.571 21.0464Z"
        fill="#FFBD3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5715 23.4604V25.3081V25.3083V27.1559L38.5537 25.3083H38.5539L38.5538 25.3082L38.5539 25.3081H38.5537L36.5715 23.4604Z"
        fill="#02B05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.591 25.3079L36.5728 23.4607L36.5728 25.3085L36.5727 25.3085L36.5727 27.1558L34.591 25.3085L34.5904 25.3085L34.5907 25.3082L34.5904 25.3079L34.591 25.3079Z"
        fill="#7BCC29"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.7144 21.0464V25.308H45.7153L45.7153 29.5696L50.2871 25.308H50.2862L45.7144 21.0464Z"
        fill="#FFBD3C"
      />
      <path
        d="M36.5714 33.8314V29.5698L41.1433 33.8314H36.5714Z"
        fill="#FFBD3C"
      />
      <path
        d="M45.7147 33.8314V29.5698L50.2866 33.8314H45.7147Z"
        fill="#FFBD3C"
      />
      <path
        d="M36.571 16.7852L41.1429 16.7852L36.571 21.0468L36.571 16.7852Z"
        fill="#FFBD3C"
      />
      <path
        d="M45.7144 16.7852L50.2862 16.7852L45.7144 21.0468L45.7144 16.7852Z"
        fill="#FFBD3C"
      />
      <path
        d="M15.996 4H19.4338L13.719 16.9264H10.2811L15.996 4Z"
        fill="#8E7F20"
      />
      <path
        d="M15.9958 4H47.999L53.7139 16.7849H21.7107L15.9958 4Z"
        fill="#554B0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.7148 23.4604V25.3081V25.3083V27.1559L47.697 25.3083H47.6972L47.6971 25.3082L47.6972 25.3081H47.697L45.7148 23.4604Z"
        fill="#02B05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.7158 27.1558L45.7158 25.3079L45.7149 25.3079L45.7149 23.4608L43.7326 25.3086L43.7341 25.3086L45.7158 27.1558Z"
        fill="#7BCC29"
      />
      <rect
        x="52.7361"
        y="25.5732"
        width="0.571484"
        height="8.2569"
        fill="#554B0C"
      />
      <path
        d="M56.8476 24.4726C56.8476 26.4417 55.1351 28.038 53.0225 28.038C50.91 28.038 49.1975 26.4417 49.1975 24.4726C49.1975 22.5034 50.91 13.189 53.0225 13.189C55.1351 13.189 56.8476 22.5034 56.8476 24.4726Z"
        fill="#018B46"
      />
    </svg>
  );
};

export default GoalHouse;
