import { forwardRef, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import useOnClickOutside from '@/hooks/useOnClickOutside'
import useCombinedRef from '@/hooks/useCombinedRef'
import PinaLink from '@/components/basic/pina-link'
import ChevronDownMenu from '@/components/svg/chevron-down-menu'
import styles from '@/components/layout/css/header.module.css'
import useWindowSize from '@/hooks/useWindowSize'

const MenuItem = forwardRef(({ menu = {}, hasSubmenu = false, onItemClick, widthParent }, ref) => {
  const router = useRouter()
  const [expandMenu, setExpandMenu] = useState(false)
  
  const _ref = useRef(null)
  const innerRef = useCombinedRef(ref, _ref)
  const screenSize = useWindowSize();

  useOnClickOutside(innerRef, () => {
    if (screenSize.width > 1024) {
      setExpandMenu(false);
    }
  })

  const toggleSubmenu = () => {
    setExpandMenu(prev => !prev)
  }
  
  return (
    <>
      {
        hasSubmenu
        ?
          <div
            ref={innerRef}
            className={[
              // `relative`,
              `${styles['submenu']}`,
              `${expandMenu && styles['opened']}`,
              `group`
            ].join(' ')}
          >
            <div
              className={`cursor-pointer flex items-center lg:text-center font-boldmd:font-gotham-medium sm:px-0 lg:px-4 py-3 lg:py-5 text-2xl lg:text-base ${menu.hoverClass}`}
              onClick={toggleSubmenu}
            >
              <span>{menu?.name}</span>
              <span className={`block ml-3 lg:ml-2`}>
                <ChevronDownMenu
                  className={`${styles["icon-chevron"]} stroke-current stroke-1 h-5 w-5 lg:h-4 lg:w-4`}
                />
              </span>
            </div>
            <div className={`${styles["submenu-content"]}`} style={{right: (screenSize.width || 0) >= 1024 && `calc((-50vw) + (${widthParent}px / 2))`}}>
              <div className='container w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 lg:mx-auto lg:px-4'>
                <ul className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8'>
                  {menu?.children.map((submenu, sIdx) => (
                    <li
                      key={sIdx}
                      onClick={() => {
                        typeof onItemClick === 'function' && onItemClick();
                      }}
                      className={`bg-white rounded-md border border-transparent ${submenu.hoverBorderClass}`}
                    >
                      <PinaLink
                        server={submenu.isExternal || submenu.needRewrite}
                        target={submenu.target}
                        href={submenu.path}
                        className="flex p-4 items-center h-full"
                      >
                        {screenSize.width < 1024 && expandMenu &&  <span className="flex-none mr-4" dangerouslySetInnerHTML={{__html: submenu.icon}}/>}
                        {(screenSize.width >= 1024) && <span className="flex-none mr-4" dangerouslySetInnerHTML={{__html: submenu.icon}}/>}
                        <div className="flex-grow text-sm">
                          <h5 className="font-gotham-medium">{submenu.name}</h5>
                          <p className="font-gotham-book">{submenu.subTitle}</p>
                        </div>
                      </PinaLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        :
          <div onClick={onItemClick}>
            <PinaLink
              server={menu.isExternal || menu.needRewrite}
              target={menu.target}
              href={menu.path}
              className={`block lg:text-center font-boldmd:font-gotham-medium transition-all duration-200 ease-in-out sm:px-0 lg:px-4 py-3 lg:py-5 text-2xl lg:text-base ${
                menu.hoverClass
              } ${
                menu.path === router.pathname ? `${menu.activeClass}` : ""
              }`}
            >
              {menu.name}
            </PinaLink>
          </div>
      }
    </>
  )
})

MenuItem.displayName = "MenuItem"

export default MenuItem