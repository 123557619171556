export default function IconMenuAbout({ className }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#F5F7F7" />
      <mask
        id="mask0_4076_43029"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <circle cx="24" cy="24" r="24" fill="#FFF19A" />
      </mask>
      <g mask="url(#mask0_4076_43029)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8802 29.1856V19.8242L28.3617 24.5051L32.8802 29.1856Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.9374 38.9065V29.3055L28.3027 34.1062L32.9374 38.9065Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6692 29.3053V19.7047L19.0341 24.5046L23.6692 29.3053Z"
          fill="#FCB813"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6692 19.7047V29.3053L28.303 24.505L23.6692 19.7047Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4001 19.7047V29.3053L19.0344 24.505L14.4001 19.7047Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4001 29.3054V38.9056L19.0344 34.1057L14.4001 29.3054Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3029 34.1063V24.5049L23.669 29.3051L28.3029 34.1063Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3029 24.5049V34.1063L32.9375 29.3056L28.3029 24.5049Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0342 34.1063V24.5049L14.4 29.3051L19.0342 34.1063Z"
          fill="#FCB813"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0342 24.5049V34.1063L23.6693 29.3056L19.0342 24.5049Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6692 38.9065V29.3055L19.0341 34.1053L23.6692 38.9065Z"
          fill="#FCB813"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6692 29.3054V38.9064L28.303 34.1066L23.6692 29.3054Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3029 43.7067V34.1057L23.669 38.9055L28.3029 43.7067Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3029 34.1056V43.7066L32.9375 38.9067L28.3029 34.1056Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0342 43.7067V34.1057L14.4 38.9055L19.0342 43.7067Z"
          fill="#FCB813"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0342 34.1056V43.7066L23.6693 38.9067L19.0342 34.1056Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0338 43.7067H28.3027L23.6684 38.9055L19.0338 43.7067Z"
          fill="#FCB813"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6692 10.1068V19.7078L28.303 14.908L23.6692 10.1068Z"
          fill="#76B0B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6683 19.7081V10.107L19.0337 14.9069L23.6683 19.7081Z"
          fill="#5F9DA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0347 5.30666V14.9077L23.6693 10.1078L19.0347 5.30666Z"
          fill="#76B0B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0342 14.9079V5.30685L14.4 10.1076L19.0342 14.9079Z"
          fill="#5F9DA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3037 5.30666V14.9077L32.9379 10.1078L28.3037 5.30666Z"
          fill="#76B0B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3029 14.9079V5.30685L23.669 10.1076L28.3029 14.9079Z"
          fill="#76B0B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3029 24.5048V14.9046L23.669 19.7045L28.3029 24.5048Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3029 14.9045V24.5051L32.9375 19.7048L28.3029 14.9045Z"
          fill="#FFDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0342 24.5048V14.9046L14.4 19.7045L19.0342 24.5048Z"
          fill="#FCB813"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0342 14.9045V24.5051L23.6693 19.7048L19.0342 14.9045Z"
          fill="#FFDC00"
        />
      </g>
    </svg>
  );
}
