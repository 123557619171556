import Link from 'next/link'
import PropTypes from 'prop-types'

const PinaLink = ({ children, server, target, className, ...props }) => {
  if (server) {
    const _target = target || "_self"
    return (
      <a className={className} target={_target} {...props}>{children}</a>
    )
  }

  // client-side transitions between routes
  return (
    <Link {...props}>
      <a className={className}>
        {children}
      </a>
    </Link>
  )
}

PinaLink.propTypes = {
  server: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
}

PinaLink.defaultProps = {
  server: false,
  className: '',
  children: null
}

export default PinaLink