export default function IconMenuCanddleStick({ className }) {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24.5" r="24" fill="#F5F7F7" />
      <mask
        id="mask0_3125_31132"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="49"
      >
        <circle cx="24" cy="24.5" r="24" fill="#F5F7F7" />
      </mask>
      <g mask="url(#mask0_3125_31132)">
        <path
          d="M22.1662 36.8292H25.74V48.6917C25.74 49.1424 25.56 49.5747 25.2396 49.8934C24.9192 50.2122 24.4846 50.3912 24.0315 50.3912H23.8747C23.4215 50.3912 22.987 50.2122 22.6666 49.8934C22.3461 49.5747 22.1661 49.1424 22.1661 48.6917V36.8292H22.1662Z"
          fill="#61A818"
        />
        <path
          d="M25.7404 23.662H22.1666V11.7995C22.1666 11.3488 22.3466 10.9165 22.667 10.5978C22.9874 10.279 23.422 10.1 23.8751 10.1H24.0319C24.4851 10.1 24.9196 10.279 25.2401 10.5978C25.5605 10.9165 25.7405 11.3488 25.7405 11.7995V23.662H25.7404Z"
          fill="#61A818"
        />
        <path d="M17.4 16.7H30.5064V39.8H17.4V16.7Z" fill="#83CE2C" />
        <path d="M24 16.7H30.6V39.8H24V16.7Z" fill="#68A720" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6 20.309V16.7H28.6408L27.7855 17.5482L30.6 20.309ZM27.7791 16.7H26.9128L27.7814 17.5521L27.7814 17.5442L27.7814 17.5442L27.7791 16.7ZM24.0079 16.7H24V39.8H24.0718L24.0617 36.1293L27.8116 39.8H27.843L27.8225 32.4074L27.8225 32.4074V32.4073L24.0617 36.1293L24.0616 36.1294L24.0411 28.6979L27.8225 32.4072L27.802 24.9679L30.6 27.7126V22.2045L27.8019 24.9678L27.7815 17.5522L27.7814 17.5522L24.0206 21.2743L24.0079 16.7ZM30.6 29.6527L27.8226 32.4073L30.6 35.126V29.6527ZM30.6 37.1025L27.8744 39.8H30.6V37.1025ZM24.0206 21.2743L27.8019 24.9679L24.0411 28.6979L24.041 28.6979L24.0205 21.2742L24.0206 21.2743Z"
          fill="#6FB810"
        />
        <path
          d="M37.2 25.5H39.7827V34.0727C39.7827 34.3984 39.6526 34.7108 39.421 34.9412C39.1895 35.1715 38.8754 35.3009 38.548 35.3009H38.4347C38.1072 35.3009 37.7931 35.1715 37.5616 34.9412C37.33 34.7108 37.2 34.3984 37.2 34.0727V25.5H37.2Z"
          fill="#E9A500"
        />
        <path
          d="M39.5999 26.3009H37.0172V17.7282C37.0172 17.4025 37.1473 17.0901 37.3789 16.8597C37.6104 16.6294 37.9245 16.5 38.2519 16.5H38.3653C38.6927 16.5 39.0068 16.6294 39.2383 16.8597C39.4699 17.0901 39.6 17.4025 39.6 17.7282V26.3009H39.5999Z"
          fill="#E9A500"
        />
        <path d="M33.6 20.9H43.2V31.1H33.6V20.9Z" fill="#FFDC00" />
        <path
          d="M11.0272 26.3009H8.44449V17.7282C8.44449 17.4025 8.57457 17.0901 8.80613 16.8597C9.03768 16.6294 9.35173 16.5 9.6792 16.5H9.79251C10.12 16.5 10.434 16.6294 10.6656 16.8597C10.8971 17.0901 11.0272 17.4025 11.0272 17.7282L11.0272 26.3009H11.0272Z"
          fill="#E9A500"
        />
        <path
          d="M8.44454 28.5124H11.0272V37.0851C11.0272 37.4108 10.8971 37.7232 10.6656 37.9536C10.434 38.1839 10.12 38.3133 9.7925 38.3133H9.67917C9.3517 38.3133 9.03765 38.1839 8.8061 37.9536C8.57454 37.7232 8.44446 37.4108 8.44446 37.0851V28.5124H8.44454Z"
          fill="#E9A500"
        />
        <path d="M4.80005 22.1H14.4V35.3H4.80005V22.1Z" fill="#FFDC00" />
        <rect x="9.6001" y="22.1" width="4.8" height="13.2" fill="#FCB813" />
        <rect x="38.4" y="20.9" width="4.8" height="10.2" fill="#FCB813" />
      </g>
    </svg>
  );
}
