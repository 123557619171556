const GoalRetirement = ({ className = "" }) => {
  return (
    <svg
      width="64"
      height="40"
      viewBox="0 0 64 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 8.00196C12 5.79282 13.7909 4.00195 16 4.00195H40.8779V33.2701H12V8.00196Z"
        fill="#99F041"
      />
      <path
        d="M40.8777 15.7077L40.8777 4.00049L29.1704 15.7077L40.8777 15.7077Z"
        fill="#02B05F"
      />
      <path
        d="M40.8777 15.71L40.8777 4.0027L52.585 15.71L40.8777 15.71Z"
        fill="#C8FDFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1699 15.7073L29.1699 4L17.4626 4L29.1699 15.7073ZM29.17 15.7073L29.17 4L40.8772 4L29.17 15.7073Z"
        fill="#99F041"
      />
      <rect
        x="40.8772"
        y="4.00049"
        width="11.7073"
        height="29.2682"
        fill="#02B05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8779 15.7085V21.5621V21.5621V27.4157L46.7315 21.5621H46.7316L46.7315 21.5621L46.7316 21.5621H46.7315L40.8779 15.7085Z"
        fill="#018947"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8772 9.85356V4L46.7308 9.85356H46.7308L46.7308 9.85359L46.7308 9.85363H46.7308L40.8772 15.7072H40.8776V21.5608L35.0239 15.7072H40.8772V9.85363V9.85356Z"
        fill="#018947"
      />
      <path
        d="M40.8779 33.2696V27.416L46.7316 33.2696H40.8779Z"
        fill="#018947"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.731 21.5615V27.4151V27.4152V33.2687L52.5845 27.4152H52.5846L52.5845 27.4151L52.5846 27.4151H52.5845L46.731 21.5615Z"
        fill="#018947"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.731 9.854V15.7076V15.7076V21.5612L52.5845 15.7076H52.5846L52.5845 15.7076L52.5846 15.7076H52.5845L46.731 9.854Z"
        fill="#018947"
      />
      <path
        d="M46.731 4.00049L52.5846 4.00049L46.731 9.85412L46.731 4.00049Z"
        fill="#018947"
      />
      <circle cx="26.4381" cy="18.8311" r="9.36581" fill="#FFDC00" />
      <path
        d="M17.0723 18.4404H17.8528C18.0683 18.4404 18.243 18.6151 18.243 18.8307C18.243 19.0462 18.0683 19.2209 17.8528 19.2209H17.0723V18.4404Z"
        fill="#C58E08"
      />
      <path
        d="M35.8042 18.4404H35.0237C34.8082 18.4404 34.6335 18.6151 34.6335 18.8307C34.6335 19.0462 34.8082 19.2209 35.0237 19.2209H35.8042V18.4404Z"
        fill="#C58E08"
      />
      <path
        d="M26.8284 9.46436L26.8284 10.2448C26.8284 10.4604 26.6537 10.6351 26.4381 10.6351C26.2226 10.6351 26.0479 10.4604 26.0479 10.2448L26.0479 9.46436L26.8284 9.46436Z"
        fill="#C58E08"
      />
      <path
        d="M26.8284 28.1963L26.8284 27.4158C26.8284 27.2003 26.6537 27.0256 26.4381 27.0256C26.2226 27.0256 26.0479 27.2003 26.0479 27.4158L26.0479 28.1963L26.8284 28.1963Z"
        fill="#C58E08"
      />
      <path
        d="M33.3372 12.4824L32.7853 13.0343C32.6329 13.1867 32.3858 13.1867 32.2334 13.0343C32.081 12.8819 32.081 12.6348 32.2334 12.4824L32.7853 11.9305L33.3372 12.4824Z"
        fill="#C58E08"
      />
      <path
        d="M20.0913 25.7285L20.6432 25.1766C20.7956 25.0242 20.7956 24.7771 20.6432 24.6247C20.4908 24.4723 20.2437 24.4723 20.0913 24.6247L19.5394 25.1766L20.0913 25.7285Z"
        fill="#C58E08"
      />
      <path
        d="M32.7844 25.7285L32.2325 25.1766C32.0801 25.0242 32.0801 24.7771 32.2325 24.6247C32.3849 24.4723 32.632 24.4723 32.7844 24.6247L33.3363 25.1766L32.7844 25.7285Z"
        fill="#C58E08"
      />
      <path
        d="M19.5393 12.4824L20.0912 13.0343C20.2436 13.1867 20.4907 13.1867 20.6431 13.0343C20.7955 12.8819 20.7955 12.6348 20.6431 12.4824L20.0912 11.9305L19.5393 12.4824Z"
        fill="#C58E08"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4382 13.7568V18.8294V18.83V23.9026L31.5108 18.83H31.5114L31.5111 18.8297L31.5114 18.8294H31.5108L26.4382 13.7568Z"
        fill="#C58E08"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4382 23.9038L26.4382 18.8307L21.3651 18.8307L26.4382 23.9038ZM26.4382 18.8307L26.4382 13.7576L21.3651 18.8307L26.4382 18.8307Z"
        fill="#FCB813"
      />
      <rect
        x="14.731"
        y="33.2686"
        width="2.7317"
        height="2.7317"
        fill="#D19502"
      />
      <rect
        x="34.2434"
        y="33.2686"
        width="2.73169"
        height="2.7317"
        fill="#D19502"
      />
      <rect
        x="47.9014"
        y="33.2686"
        width="2.7317"
        height="2.7317"
        fill="#704F00"
      />
      <rect
        x="28.3896"
        y="33.2686"
        width="2.7317"
        height="2.7317"
        fill="#704F00"
      />
    </svg>
  );
};

export default GoalRetirement;
