import GoalHouse from '@/components/svg/goal/house'
import GoalRetirement from '@/components/svg/goal/retirement'
import IconMenuAbout from '@/components/svg/menu/icon-menu-about'
import IconMenuAman from '@/components/svg/menu/icon-menu-aman'
import IconMenuBankConnect from '@/components/svg/menu/icon-menu-bank-connect'
import IconMenuBudgeting from '@/components/svg/menu/icon-menu-budgeting'
import IconMenuCanddleStick from '@/components/svg/menu/icon-menu-canddle-stick'
import IconMenuGoal from '@/components/svg/menu/icon-menu-goal'
import IconMenuInvest from '@/components/svg/menu/icon-menu-invest'
import IconMenuSave from '@/components/svg/menu/icon-menu-save'
import ReactDOMServer from 'react-dom/server'

const menus = [
  {
    "name": "Net Worth",
    "path": "/hitung-net-worth",
    "activeClass": "text-blue-300",
    "activeBackgroundClass": "bg-blue-300",
    "hoverClass": "hover:text-blue-300",
    "isExternal": false,
    "needRewrite": false,
  },
  {
    "name": "Save",
    "path": "#",
    "activeClass": "text-orange-100",
    "activeBackgroundClass": "bg-orange-100",
    "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
    "isExternal": false,
    "needRewrite": false,
    "children": [
      {
        "name": "PINA Save",
        "path": "/aplikasi-keuangan",
        "activeClass": "text-orange-100",
        "activeBackgroundClass": "bg-orange-100",
        "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
        "hoverBorderClass": "hover:border-orange-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<IconMenuSave className="h-12 w-12 rounded-full bg-gray-1 overflow-hidden" />),
        "subTitle": 'Kendalikan uangmu dengan 1 aplikasi.'
      },
      {
        "name": "Kelola Budget",
        "path": "/aplikasi-catatan-keuangan",
        "activeClass": "text-orange-100",
        "activeBackgroundClass": "bg-orange-100",
        "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
        "hoverBorderClass": "hover:border-orange-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<IconMenuBudgeting className="h-12 w-12 rounded-full bg-gray-1 overflow-hidden" />),
        "subTitle": 'Rencanakan keuangan jadi lebih mudah.'
      },
      {
        "name": "Hubungkan Akun",
        "path": "/cek-saldo-online",
        "activeClass": "text-orange-100",
        "activeBackgroundClass": "bg-orange-100",
        "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
        "hoverBorderClass": "hover:border-orange-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<IconMenuBankConnect className="h-12 w-12 rounded-full bg-gray-1 overflow-hidden" />),
        "subTitle": 'Cek saldo rekening & ewallet dari PINA.'
      },
    ]
  },
  {
    "name": "Invest",
    "path": "#",
    "activeClass": "text-green-100",
    "activeBackgroundClass": "bg-green-100",
    "hoverClass": "hover:text-green-100 group-hover:text-green-100",
    "isExternal": false,
    "needRewrite": false,
    "children": [
      {
        "name": "PINA Invest",
        "path": "/aplikasi-investasi",
        "activeClass": "text-green-100",
        "activeBackgroundClass": "bg-green-100",
        "hoverClass": "hover:text-green-100 group-hover:text-green-100",
        "hoverBorderClass": "hover:border-green-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<IconMenuInvest className="h-12 w-12 rounded-full bg-gray-1 overflow-hidden" />),
        "subTitle": 'Investasi cukup di 1 Aplikasi.'
      },
      {
        "name": "PINA Goal",
        "path": "/financial-goal",
        "activeClass": "text-green-100",
        "activeBackgroundClass": "bg-green-100",
        "hoverClass": "hover:text-green-100 group-hover:text-green-100",
        "hoverBorderClass": "hover:border-green-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<IconMenuGoal className="h-12 w-12 rounded-full bg-gray-1 overflow-hidden" />),
        "subTitle": 'Investasi otomatis berbasis Financial Goal.'
      },
      {
        "name": "Investasi Aktif",
        "path": "/mulai-investasi",
        "activeClass": "text-green-100",
        "activeBackgroundClass": "bg-green-100",
        "hoverClass": "hover:text-green-100 group-hover:text-green-100",
        "hoverBorderClass": "hover:border-green-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<IconMenuCanddleStick className="h-12 w-12 rounded-full bg-gray-1 overflow-hidden" />),
        "subTitle": 'Mudah berinvestasi saham & reksadana.'
      },
    ]
  },
  {
    "name": "Classroom",
    "path": "/classroom",
    "activeClass": "text-olive",
    "activeBackgroundClass": "bg-turquoise-100",
    "hoverClass": "hover:text-olive",
    "isExternal": false,
    "needRewrite": false,
  },
  {
    "name": "Kalkulator",
    "path": "#",
    "activeClass": "text-orange-100",
    "activeBackgroundClass": "bg-orange-100",
    "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
    "isExternal": false,
    "needRewrite": false,
    "children": [
      {
        "name": "Kalkulator Dana Darurat",
        "path": "/hitung-dana-darurat",
        "activeClass": "text-orange-100",
        "activeBackgroundClass": "bg-orange-100",
        "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
        "hoverBorderClass": "hover:border-orange-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<GoalRetirement className='h-12 w-12 rounded-full bg-gray-1 overflow-hidden' />),
        "subTitle": 'Siapkan tabungan hadapi kondisi darurat.'
      },
      {
        "name": "Kalkulator Dana Pensiun",
        "path": "/kalkulator-dana-pensiun",
        "activeClass": "text-orange-100",
        "activeBackgroundClass": "bg-orange-100",
        "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
        "hoverBorderClass": "hover:border-orange-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<GoalRetirement className='h-12 w-12 rounded-full bg-gray-1 overflow-hidden' />),
        "subTitle": 'Hitung & persiapan dana pensiun.'
      },
      {
        "name": "Kalkulator Rumah Impian",
        "path": "/kalkulator-tabungan-rumah",
        "activeClass": "text-orange-100",
        "activeBackgroundClass": "bg-orange-100",
        "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
        "hoverBorderClass": "hover:border-orange-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<GoalHouse className='h-12 w-12 rounded-full bg-gray-1 overflow-hidden' />),
        "subTitle": 'Siapkan target untuk punya rumah impian.'
      },
    ]
  },
  {
    "name": "Promo",
    "path": "/promo",
    "activeClass": "text-orange-100",
    "activeBackgroundClass": "bg-orange-100",
    "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
    "isExternal": false,
    "needRewrite": false
  },
  {
    "name": "Tentang Kami",
    "path": "#",
    "activeClass": "text-orange-100",
    "activeBackgroundClass": "bg-orange-100",
    "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
    "isExternal": false,
    "needRewrite": false,
    "children": [
      {
        "name": "Siapa Kami",
        "path": "/about",
        "activeClass": "text-orange-100",
        "activeBackgroundClass": "bg-orange-100",
        "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
        "hoverBorderClass": "hover:border-orange-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<IconMenuAbout className="h-12 w-12 rounded-full bg-gray-1 overflow-hidden" />),
        "subTitle": 'Tujuan dan prinsip PINA'
      },
      {
        "name": "Keamanan PINA",
        "path": "/keamanan-investasi",
        "activeClass": "text-orange-100",
        "activeBackgroundClass": "bg-orange-100",
        "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
        "hoverBorderClass": "hover:border-orange-100",
        "isExternal": false,
        "icon": ReactDOMServer.renderToString(<IconMenuAman className="h-12 w-12 rounded-full bg-gray-1 overflow-hidden" />),
        "subTitle": 'Regulasi dan keamanan PINA'
      }
    ]
  },
  // {
  //   "name": "Pusat Bantuan",
  //   "path": "https://help.pina.id",
  //   "activeClass": "text-orange-100",
  //   "activeBackgroundClass": "bg-orange-100",
  //   "hoverClass": "hover:text-orange-100 group-hover:text-orange-100",
  //   "isExternal": true,
  //   "target": "_blank", // only for isExternal = true
  //   "needRewrite": false,
  // }
]

export default menus