export default function IconMenuAman({ className }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#F5F7F7" />
      <mask
        id="mask0_4076_43063"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <circle cx="24" cy="24" r="24" fill="#FFF19A" />
      </mask>
      <g mask="url(#mask0_4076_43063)">
        <path
          d="M23.6618 41.63L23.9055 41.7181L24.1492 41.63C41.9407 35.4048 41.5494 12.0423 41.5494 11.8069L41.5276 11.0641L40.7823 11.0932C34.7646 11.2995 24.2452 6.47256 24.1417 6.421L23.8313 6.28125L23.5209 6.421C23.4174 6.47256 12.868 11.3219 6.88099 11.0932L6.12822 11.0641L6.11322 11.8144C6.11397 12.0498 5.87704 35.4048 23.6618 41.63Z"
          fill="#FCB813"
        />
        <mask
          id="mask1_4076_43063"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="6"
          width="36"
          height="36"
        >
          <path
            d="M23.6613 41.63L23.905 41.7181L24.1486 41.63C41.9402 35.4048 41.5488 12.0423 41.5488 11.8069L41.5271 11.0641L40.7818 11.0932C34.764 11.2995 24.2446 6.47256 24.1411 6.421L23.8307 6.28125L23.5203 6.421C23.4169 6.47256 12.8674 11.3219 6.88044 11.0932L6.12767 11.0641L6.11267 11.8144C6.11342 12.0498 5.87649 35.4048 23.6613 41.63Z"
            fill="#3A92CC"
          />
        </mask>
        <g mask="url(#mask1_4076_43063)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.5488 12.7003L41.5501 12.7003L41.5494 12.7007L41.55 12.7012L41.5488 12.7012L32.6908 18.6064L32.6908 12.7012L32.6908 6.7951L41.5488 12.7003Z"
            fill="#C98E00"
          />
          <path
            d="M32.6908 18.6068L23.8316 18.6068L23.8316 12.7007L32.6908 18.6068Z"
            fill="#C98E00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6908 0.888456L41.5501 0.888449L32.6908 6.79452L32.6908 0.888456ZM32.6908 0.888351L41.55 0.888359L32.6908 -5.01771L32.6908 0.888351Z"
            fill="#C98E00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.69 6.79408L32.6908 6.79408L32.6904 6.79435L32.6908 6.79461L32.69 6.79461L23.8316 12.7001L23.8316 6.7946L23.8316 0.888542L32.69 6.79408Z"
            fill="#C98E00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6908 36.3254L41.5501 36.3254L32.6908 42.2315L32.6908 36.3254ZM32.6908 36.3253L41.55 36.3253L32.6908 30.4192L32.6908 36.3253Z"
            fill="#C98E00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6901 42.2311L32.6908 42.2311L32.6904 42.2313L32.6908 42.2315L32.6901 42.2315L23.8316 48.1371L23.8316 42.2315L23.8316 36.3255L32.6901 42.2311Z"
            fill="#C98E00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6908 24.5132L41.5501 24.5132L32.6908 30.4193L32.6908 24.5132ZM32.6908 24.5131L41.55 24.5131L32.6908 18.607L32.6908 24.5131Z"
            fill="#C98E00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.69 30.4189L32.6908 30.4189L32.6904 30.4191L32.6908 30.4194L32.69 30.4194L23.8316 36.3249L23.8316 30.4193L23.8316 24.5133L32.69 30.4189Z"
            fill="#C98E00"
          />
          <path
            d="M32.6909 18.6072L23.8316 18.6073L23.8316 24.5133L32.6909 18.6072Z"
            fill="#C98E00"
          />
        </g>
        <path
          d="M23.684 38.5639L23.8959 38.6367L24.1078 38.5639C39.5787 33.4214 39.2383 14.1219 39.2383 13.9274L39.2194 13.3138L38.5714 13.3379C33.3386 13.5082 24.1912 9.5208 24.1013 9.47821L23.8314 9.36276L23.5614 9.47821C23.4715 9.5208 14.2981 13.5268 9.09199 13.3379L8.4374 13.3138L8.42436 13.9336C8.42501 14.1281 8.21899 33.4214 23.684 38.5639Z"
          fill="#FFDC00"
        />
        <mask
          id="mask2_4076_43063"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="9"
          width="32"
          height="30"
        >
          <path
            d="M23.684 38.5639L23.8959 38.6367L24.1078 38.5639C39.5787 33.4214 39.2383 14.1219 39.2383 13.9274L39.2194 13.3138L38.5714 13.3379C33.3386 13.5083 24.1912 9.52081 24.1013 9.47821L23.8314 9.36277L23.5614 9.47821C23.4715 9.52081 14.2981 13.5268 9.09199 13.3379L8.4374 13.3138L8.42436 13.9336C8.42501 14.1281 8.21899 33.4214 23.684 38.5639Z"
            fill="#3A92CC"
          />
        </mask>
        <g mask="url(#mask2_4076_43063)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.5488 12.7003L41.5501 12.7003L41.5494 12.7008L41.55 12.7012L41.5488 12.7012L32.6908 18.6064L32.6908 12.7012L32.6908 6.79512L41.5488 12.7003Z"
            fill="#FCB813"
          />
          <path
            d="M32.6908 18.6068L23.8316 18.6068L23.8316 12.7008L32.6908 18.6068Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.69 6.7941L32.6908 6.7941L32.6904 6.79437L32.6908 6.79463L32.69 6.79463L23.8316 12.7002L23.8316 6.79462L23.8316 0.888565L32.69 6.7941Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6908 36.3254L41.5501 36.3254L32.6908 42.2315L32.6908 36.3254ZM32.6908 36.3253L41.55 36.3253L32.6908 30.4192L32.6908 36.3253Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6901 42.2311L32.6908 42.2311L32.6904 42.2313L32.6908 42.2316L32.6901 42.2316L23.8316 48.1371L23.8316 42.2315L23.8316 36.3255L32.6901 42.2311Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6908 24.5132L41.5501 24.5132L32.6908 30.4193L32.6908 24.5132ZM32.6908 24.5131L41.55 24.5131L32.6908 18.607L32.6908 24.5131Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.69 30.4189L32.6908 30.4189L32.6904 30.4191L32.6908 30.4194L32.69 30.4194L23.8316 36.3249L23.8316 30.4193L23.8316 24.5133L32.69 30.4189Z"
            fill="#FCB813"
          />
          <path
            d="M32.6909 18.6073L23.8316 18.6073L23.8316 24.5133L32.6909 18.6073Z"
            fill="#FCB813"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3 17.9182V25.6081C21.3 26.1672 20.8467 26.6206 20.2875 26.6206C19.7283 26.6206 19.275 26.1672 19.275 25.6081V17.9136H20.2875C19.275 17.9136 19.275 17.9131 19.275 17.9126L19.275 17.9115L19.275 17.9092L19.2751 17.9035L19.2752 17.8884C19.2754 17.8766 19.2757 17.8617 19.2763 17.8436C19.2775 17.8076 19.2796 17.7592 19.2836 17.7003C19.2916 17.5826 19.3072 17.4208 19.3377 17.2291C19.3978 16.8508 19.5199 16.3299 19.7743 15.7962C20.0301 15.2595 20.4319 14.6851 21.0599 14.2458C21.6967 13.8005 22.5048 13.5375 23.4938 13.5375C24.4828 13.5375 25.2909 13.8005 25.9277 14.2458C26.5557 14.6851 26.9575 15.2595 27.2132 15.7962C27.4676 16.3299 27.5898 16.8508 27.6499 17.2291C27.6803 17.4208 27.6959 17.5826 27.7039 17.7003C27.7079 17.7592 27.7101 17.8076 27.7112 17.8436C27.7118 17.8617 27.7121 17.8766 27.7123 17.8884L27.7125 17.9035L27.7125 17.9092L27.7125 17.9115L27.7125 17.9126C27.7125 17.9131 27.7125 17.9136 26.7 17.9136H27.7125V25.7619C27.7125 26.3211 27.2592 26.7744 26.7 26.7744C26.1408 26.7744 25.6875 26.3211 25.6875 25.7619V17.9182C25.6875 17.9175 25.6875 17.9165 25.6875 17.9152C25.6874 17.9133 25.6873 17.9109 25.6873 17.9079C25.6868 17.8947 25.6858 17.8708 25.6836 17.8378C25.6791 17.7715 25.6696 17.6705 25.65 17.5469C25.6099 17.2946 25.5316 16.9745 25.3852 16.6674C25.2402 16.3631 25.0408 16.0967 24.7671 15.9053C24.5022 15.72 24.1079 15.5625 23.4938 15.5625C22.8796 15.5625 22.4854 15.72 22.2204 15.9053C21.9467 16.0967 21.7473 16.3631 21.6023 16.6674C21.4559 16.9745 21.3777 17.2946 21.3376 17.5469C21.3179 17.6705 21.3085 17.7715 21.3039 17.8378C21.3017 17.8708 21.3007 17.8947 21.3003 17.9079C21.3001 17.913 21.3001 17.9164 21.3 17.9182Z"
          fill="#006734"
        />
        <path
          d="M15.9 27.8956C15.9 24.0255 19.0373 20.8881 22.9074 20.8881H25.3557C28.8941 20.8881 31.7625 23.7566 31.7625 27.2949V27.2949C31.7625 29.9487 29.6112 32.1 26.9574 32.1H20.1045C17.7824 32.1 15.9 30.2176 15.9 27.8956V27.8956Z"
          fill="#06944E"
        />
        <path
          d="M15.9 27.4694C15.9 23.8347 18.8465 20.8881 22.4813 20.8881V20.8881C26.116 20.8881 29.0625 23.8347 29.0625 27.4694V27.8956C29.0625 30.2176 27.1801 32.1 24.8581 32.1H20.1045C17.7824 32.1 15.9 30.2176 15.9 27.8956V27.4694Z"
          fill="#01A454"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7624 30.21L29.0626 32.0998L29.0626 30.21L29.0626 30.2099L29.0626 28.32L31.7624 30.2099L31.7626 30.2099L31.7625 30.2099L31.7626 30.21L31.7624 30.21Z"
          fill="#7BCC29"
        />
        <path
          d="M29.0626 32.1L26.3626 32.1L26.3626 30.21L29.0626 32.1Z"
          fill="#7BCC29"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7624 26.43L29.0626 28.3198L29.0626 26.43L29.0626 26.4299L29.0626 24.54L31.7624 26.4298L31.7626 26.4298L31.7625 26.4299L31.7626 26.43L31.7624 26.43Z"
          fill="#7BCC29"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.0624 28.32L26.3626 30.2099L26.3626 28.32L26.3626 28.3199L26.3626 26.43L29.0624 28.3199L29.0626 28.3199L29.0625 28.32L29.0626 28.32L29.0624 28.32Z"
          fill="#7BCC29"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7625 22.65L29.0626 24.5399L29.0626 22.65L29.0626 22.6499L29.0626 20.76L31.7625 22.6499L31.7626 22.6499L31.7625 22.6499L31.7626 22.65L31.7625 22.65Z"
          fill="#7BCC29"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.0624 24.54L26.3626 26.4299L26.3626 24.54L26.3626 24.5399L26.3626 22.6501L29.0624 24.5399L29.0626 24.5399L29.0625 24.54L29.0626 24.54L29.0624 24.54Z"
          fill="#7BCC29"
        />
        <path
          d="M29.0626 20.7601L26.3626 20.7601L26.3626 22.65L29.0626 20.7601Z"
          fill="#7BCC29"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.65 27.482C23.246 27.2349 23.6625 26.6704 23.6625 26.0136C23.6625 25.129 22.907 24.4119 21.975 24.4119C21.0431 24.4119 20.2875 25.129 20.2875 26.0136C20.2875 26.6704 20.704 27.2349 21.3 27.482V28.542C21.3 28.9148 21.6022 29.217 21.975 29.217C22.3478 29.217 22.65 28.9148 22.65 28.542V27.482Z"
          fill="#006734"
        />
      </g>
    </svg>
  );
}
