import Image from "next/image";
import { forwardRef } from "react";
import PinaLink from "@/components/basic/pina-link";
import MenuItem from "@/components/layout/menu/menu-item";
import styles from "@/components/layout/css/header.module.css";

import LogoAppStore from "@/public/assets/logo/app-store.png";
import LogoPlayStore from "@/public/assets/logo/play-store.png";
import { DOWNLOAD_PINA_LINK } from "@/constants/links";


const Menu = forwardRef(({ items = [], showAppLink = true, open = true, onItemClick, widthParent }, ref) => {
  const year = new Date().getFullYear()

  return (
    <nav
      className={[
        `${styles["menus-height"]}`,
        `${open ? styles["menu-active"] : ''}`,
        `bg-gray-1 w-full px-6 pt-20 absolute font-boldmd:px-5`,
        `lg:relative lg:bg-transparent lg:pt-0 lg:flex lg:w-full`,
        `lg:justify-center lg:px-0 transition-transform duration-500 ease-in-out`,
        `${open ? "h-0 lg:h-auto delay-100" : "lg:h-12 lg:mt-0 delay-200"}`,
      ].join(' ')}
    >
      <ul
        className={`lg:flex lg:items-center lg:w-full justify-center ${styles["list-navigation"]}`}
      >
        {items.map((item, idx) => (
          <li key={idx} className={!open ? 'list-fade' : ''}>
            <MenuItem
              ref={ref}
              menu={item}
              widthParent={widthParent}
              hasSubmenu={
                Array.isArray(item?.children) &&
                item?.children?.length > 0
              }
              onItemClick={onItemClick}
            />
          </li>
        ))}
      </ul>

      <div className="lg:flex items-center lg:justify-end lg:mt-0 mr-0 lg:w-auto w-full lg:relative my-20 lg:my-0">
        <div className="grid grid-cols-2 gap-2 lg:gap-0 sm:max-w-xs lg:flex items-center lg:justify-end">
          {/* <PinaLink className={`hidden lg:block px-4 py-2 rounded-sm text-olive text-sm`} href={`${process.env.NEXT_PUBLIC_APP_BASE_URL}/login`}>
            Log&nbsp;In
          </PinaLink> */}
          
          <PinaLink className={`lg:mr-2`} href={DOWNLOAD_PINA_LINK} target="_blank">
            <div
              className={`rounded-2xs overflow-hidden transition duration-200 ease-in-out h-12 lg:h-7 lg:w-24 relative hidden lg:block ${
                showAppLink
                  ? "cursor-pointer opacity-100"
                  : "opacity-0 cursor-default"
              }`}
            >
              <Image
                src={LogoAppStore}
                alt="Pina App Store"
                layout="responsive"
              />
            </div>

            <div className="block w-full lg:hidden rounded-xs overflow-hidden">
              <Image
                src={LogoAppStore}
                alt="Pina App Store"
                layout="responsive"
              />
            </div>
          </PinaLink>

          <PinaLink href={DOWNLOAD_PINA_LINK} target="_blank">
            <div
              className={`rounded-2xs overflow-hidden transition duration-200 ease-in-out h-12 lg:h-7 lg:w-24 relative hidden lg:block ${
                showAppLink
                  ? "cursor-pointer opacity-100"
                  : "opacity-0 cursor-default"
              }`}
            >
              <Image
                src={LogoPlayStore}
                alt="Pina Play Store"
                layout="responsive"
              />
            </div>
            <div className="block lg:h-7 relative lg:hidden rounded-xs overflow-hidden">
              <Image
                src={LogoPlayStore}
                alt="Pina Play Store"
                layout="responsive"
              />
            </div>
          </PinaLink>
        </div>
        <p className="lg:hidden font-gotham-book text-sm mt-5">
          Copyright © {year} PT Trust Sekuritas
        </p>
      </div>
    </nav>
  );
});

Menu.displayName = "Menu";

export default Menu;
