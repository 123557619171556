import { useState, useLayoutEffect, useRef } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import Menu from "@/components/layout/menu/menu";
import Logo from "@/public/assets/logo/pina.png";
import styles from "@/components/layout/css/header.module.css";
import useWindowSize from "@/hooks/useWindowSize";

export default function Header({
  home = false,
}) {
  
   const [queryStr, setQueryStr] = useState('')
   const [isSticky, setIsSticky] = useState(false)
   const [open, setOpen] = useState(false)
   const stickyHeader = useRef()

  useLayoutEffect(() => {
        const mainHeader = document.getElementById('main-header');
        const topArea = document.getElementById('top-area');
        // let fixedTop = stickyHeader && stickyHeader.current && stickyHeader.current.offsetTop ? stickyHeader.current.offsetTop : 0
        let fixedTop = stickyHeader.current.offsetTop
        setQueryStr(window ? window.location?.search : '')
        // console.log(fixedTop)
        const fixedHeader = () => {
            if (window.pageYOffset > fixedTop) {
                mainHeader.classList.add('sticky-header')
                if(topArea) topArea.classList.add('mt-[-70px]');
                setIsSticky(true);
            } else {
                mainHeader.classList.remove('sticky-header')
                if(topArea) topArea.classList.remove('mt-[-70px]')
                setIsSticky(false);
            }
        }

        window.addEventListener('scroll', fixedHeader)
    }, [])

    const menuClick = ()=>{
      if(open){
        setOpen(false)
      }else{
        setOpen(true)
      }
    }

  return (
    <>
      <header className="absolute bg-white md:bg-transparent 
            max-h-screen z-20 top-0 w-full transition-all duration-700 ease-in-out transform py-4" id="main-header" ref={stickyHeader}>
            <div className="container px-6 w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative">
                    <div className="top-0 left-0 w-full px-0 lg:min-h-0 flex justify-between lg:items-center md:px-0 items-center ">
                      <div className="w-[180px] md:w-[200px] lg:w-[250px]">
                        <a href={"/"} className="w-[100%] h-auto flex">
                         <Image
                        src={Logo}
                        alt="Pina Indonesia"
                        layout="fixed"
                        objectFit="contain"
                        width={170}
                        height={50}
                        priority={true}
                        placeholder="blur"
                        
                      />
                      </a>
                      </div>
                  <div className="hidden md:flex">
                  
                    {/* <Link passHref href={"/blog"}><span className={`px-10 menu-btn cursor-pointer font-bold ${home ? 'text-white hover:text-olive hover:opacity-70' : 'text-black  hover:text-olive hover:opacity-70'}`}>Blog</span></Link> */}
                    {/* <a className={`px-10 ${isSticky}?'text-olive:'text-white'`}>Blog</a> */}
                    <a href={`https://app.pinatrade.id/register${queryStr}`} className="button border-olive mr-6 hover:border-olive-500  border-2 py-2 px-6 md:py-1 lg:py-1 rounded-xs  cursor-pointer transition">
                            <span className="text-olive font-medium text-xs md:text-[16px] hover:text-olive-500">Daftar</span>
                        </a>
                    <a href={`https://app.pinatrade.id${queryStr}`} className="button border-2 border-olive bg-olive py-2 px-6 md:py-1 lg:py-1 rounded-xs hover:bg-olive-500 hover:border-olive-500 cursor-pointer transition">
                            <span className="text-white font-medium text-xs md:text-[16px]">Masuk</span>
                        </a>
                    </div>

                    <div className="flex md:hidden group">
                      <a onClick={menuClick} className="space-y-1">
                        <span className="block w-6 h-0.5 bg-olive-500"></span>
                        <span className="block w-6 h-0.5 bg-olive-500"></span>
                        <span className="block w-6 h-0.5 bg-olive-500"></span>
                      </a>
                    </div>
                    
                  </div>
                </div>

                {open &&(
                  <div className="flex w-full px-6 pt-6 columns-2">
                    <a href={`https://app.pinatrade.id/register${queryStr}`} className="button grow text-center border-olive mr-6 hover:border-olive-500  border-2 py-2 px-6 md:py-1 lg:py-2 rounded-xs  cursor-pointer transition">
                            <span className="text-olive font-medium text-xs md:text-md lg:text-lg hover:text-olive-500">Daftar</span>
                        </a>
                    <a href={`https://app.pinatrade.id${queryStr}`} className="button grow text-center border-2 border-olive bg-olive py-2 px-6 md:py-1 lg:py-2 rounded-xs hover:bg-olive-500 hover:border-olive-500 cursor-pointer transition">
                            <span className="text-white font-medium text-xs md:text-md lg:text-lg">Masuk</span>
                        </a>
                  </div>
                )}


            </header>
    </>
  );
}

