export default function IconMenuInvest({ className }) {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24.5" r="24" fill="#F5F7F7" />
      <mask
        id="mask0_3125_31074"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="49"
      >
        <circle cx="24" cy="24.5" r="24" fill="#F5F7F7" />
      </mask>
      <g mask="url(#mask0_3125_31074)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 33.3243H25.0666V47.4158L24 48.4997L22.9333 47.4158V40.912V33.3243H24Z"
          fill="#61A818"
        />
        <path
          d="M24 11.5L16.2353 23.4236L24 35.3472C27.1119 35.3472 30.0963 34.0909 32.2967 31.8548C34.4971 29.6187 35.7333 26.5859 35.7333 23.4236C35.7333 20.2612 34.4971 17.2284 32.2967 14.9923C30.0963 12.7562 27.1119 11.5 24 11.5Z"
          fill="#FFDC00"
        />
        <path
          d="M12.2667 23.4236C12.2667 26.5859 13.5029 29.6187 15.7033 31.8548C17.9037 34.0909 20.8881 35.3472 24 35.3472V11.5C20.8881 11.5 17.9037 12.7562 15.7033 14.9923C13.5029 17.2284 12.2667 20.2612 12.2667 23.4236Z"
          fill="#FFDC00"
        />
        <path
          d="M24.0001 15.8361L19.059 23.4238L24.0001 31.0115C25.9804 31.0115 27.8796 30.2121 29.2798 28.7891C30.6801 27.3662 31.4668 25.4362 31.4668 23.4238C31.4668 21.4114 30.6801 19.4814 29.2798 18.0585C27.8796 16.6355 25.9804 15.8361 24.0001 15.8361Z"
          fill="#E9A500"
        />
        <path
          d="M16.5333 23.4237C16.5333 25.4361 17.3199 27.366 18.7202 28.789C20.1205 30.212 22.0196 31.0114 23.9999 31.0114V15.8359C22.0196 15.8359 20.1205 16.6354 18.7202 18.0583C17.3199 19.4813 16.5333 21.4113 16.5333 23.4237Z"
          fill="#FCB813"
        />
        <path
          d="M27.9909 23.5001L23.9956 19.5001L20 23.5001L23.9956 27.5001L27.9909 23.5001Z"
          fill="#FFDC00"
        />
        <path
          d="M24.0073 19.5059V27.4648L27.9999 23.4854L24.0073 19.5059Z"
          fill="#FCB813"
        />
        <path
          d="M35.7332 35.4923C32.6224 35.4959 29.6401 36.7533 27.4404 38.9886C25.2407 41.2239 24.0034 44.2547 23.9999 47.4159L22.9332 47.9854L23.9999 48.4999H28.2666C31.3774 48.4963 34.3597 47.2389 36.5594 45.0036C38.7591 42.7682 39.9964 39.7375 39.9999 36.5763V35.4923H35.7332Z"
          fill="#7BCC29"
        />
        <mask
          id="mask1_3125_31074"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="22"
          y="35"
          width="18"
          height="14"
        >
          <path
            d="M35.7332 35.4924C32.6224 35.496 29.6401 36.7534 27.4404 38.9887C25.2407 41.2241 24.0034 44.2548 23.9999 47.416L22.9332 47.9856L23.9999 48.5H28.2666C31.3774 48.4964 34.3597 47.239 36.5594 45.0037C38.7591 42.7684 39.9964 39.7376 39.9999 36.5764V35.4924H35.7332Z"
            fill="#7BCC29"
          />
        </mask>
        <g mask="url(#mask1_3125_31074)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9997 47.9573L23.9997 57.4047L28.6981 52.691L23.9997 47.9573Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9997 29.053L23.9997 38.5004L28.6981 33.7767L23.9997 29.053Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9999 38.5002L23.9999 47.9577L28.6984 43.224L23.9999 38.5002Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.6982 33.7756L28.6982 43.2231L33.3966 38.4994L28.6982 33.7756Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.6982 43.2239L28.6982 52.6914L33.3966 47.9576L28.6982 43.2239Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.3967 29.052L33.3967 38.4994L38.0951 33.7757L33.3967 29.052Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.3967 47.9573L33.3967 57.4047L38.0951 52.691L33.3967 47.9573Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.3967 38.5002L33.3967 47.9577L38.0951 43.224L33.3967 38.5002Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.0952 43.2239L38.0952 52.6914L42.7936 47.9576L38.0952 43.2239Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.0952 33.7756L38.0952 43.2231L42.7936 38.4994L38.0952 33.7756Z"
            fill="#60A51A"
          />
        </g>
        <path
          d="M12.2667 35.4923H8V36.5763C8.00352 39.7375 9.24084 42.7682 11.4405 45.0036C13.6402 47.2389 16.6225 48.4963 19.7333 48.4999H24V47.4159C23.9965 44.2547 22.7592 41.2239 20.5595 38.9886C18.3598 36.7533 15.3775 35.4959 12.2667 35.4923Z"
          fill="#7BCC29"
        />
      </g>
    </svg>
  );
}
