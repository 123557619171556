export default function IconMenuGoal({ className }) {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24.5" r="24" fill="#F5F7F7" />
      <mask
        id="mask0_3510_30987"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="49"
      >
        <circle cx="24" cy="24.5" r="24" fill="#F5F7F7" />
      </mask>
      <g mask="url(#mask0_3510_30987)">
        <path
          d="M45.3794 49.8778H37.6025L30.9884 40.8708L29.834 32.7601L33.7568 24.0688L45.3794 49.8778Z"
          fill="#6EBD1F"
        />
        <path
          d="M41.2793 18.9412H29.9111V8.68604H41.2793L37.8616 13.8136L41.2793 18.9412Z"
          fill="#FCB813"
        />
        <path
          d="M17.1692 32.7601L15.7748 41.3951L9.40064 49.8778H1.62378L13.2463 24.069L17.1692 32.7601Z"
          fill="#6EBD1F"
        />
        <path
          d="M37.6024 49.8777H9.40063L17.1694 32.76L19.374 27.9659H27.6293L29.8339 32.76L37.6024 49.8777Z"
          fill="#7BCC29"
        />
        <path
          d="M24.7835 13.8137V24.0688H22.2197V16.3774L24.7835 13.8137Z"
          fill="#FCB813"
        />
        <mask
          id="mask1_3510_30987"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="29"
          y="8"
          width="13"
          height="11"
        >
          <path
            d="M41.2793 18.9412H29.9111V8.68604H41.2793L37.8616 13.8136L41.2793 18.9412Z"
            fill="#FD1919"
          />
        </mask>
        <g mask="url(#mask1_3510_30987)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.6525 13.7065L29.6611 21.8862L33.7903 17.8007L29.6525 13.7065Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.6437 5.51801L29.6525 13.7064L33.7816 9.6035L29.6437 5.51801Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.7729 1.42383L33.7816 9.60356L37.9107 5.50932L33.7729 1.42383Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.79 17.8007L33.7987 25.9803L37.9278 21.8774L33.79 17.8007Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.7814 9.60352L33.79 17.8007L37.9192 13.6977L33.7814 9.60352Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.9192 13.6977L37.9279 21.8774L42.057 17.7919L37.9192 13.6977Z"
            fill="#FCB813"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.9106 5.50928L37.9193 13.6977L42.0484 9.59477L37.9106 5.50928Z"
            fill="#FCB813"
          />
        </g>
        <path
          d="M22.2197 6.12225H32.4749V16.3775H22.2197V6.12225Z"
          fill="#FFDC00"
        />
        <path
          d="M27.6294 27.9659C26.4024 28.7688 24.9679 29.1964 23.5016 29.1964C22.0353 29.1964 20.6008 28.7688 19.3738 27.9659L23.5016 18.9412L27.6294 27.9659Z"
          fill="#EAEAF9"
        />
        <mask
          id="mask2_3510_30987"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="9"
          y="27"
          width="29"
          height="23"
        >
          <path
            d="M37.6024 49.8777H9.40063L17.1694 32.76L19.374 27.9659H27.6293L29.8339 32.76L37.6024 49.8777Z"
            fill="#7BCC29"
          />
        </mask>
        <g mask="url(#mask2_3510_30987)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.5349 43.9224L23.5182 52.7955L28.0103 48.3767L23.5349 43.9224Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.5681 26.1667L23.5515 35.0398L28.0437 30.6116L23.5681 26.1667Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.5515 35.0398L23.5349 43.9223L28.0271 39.4847L23.5515 35.0398Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.0436 30.6116L28.027 39.4847L32.5191 35.0566L28.0436 30.6116Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.0104 48.3768L27.9938 57.2498L32.486 52.8123L28.0104 48.3768Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.027 39.4847L28.0104 48.3767L32.5026 43.9391L28.027 39.4847Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.5359 26.1835L32.5193 35.0566L37.0116 30.6284L32.5359 26.1835Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.5027 43.9391L32.4861 52.8125L36.9782 48.3937L32.5027 43.9391Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.5193 35.0566L32.5027 43.9391L36.9948 39.5015L32.5193 35.0566Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.9948 39.5015L36.9781 48.3932L41.4704 43.9557L36.9948 39.5015Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.9781 48.3935L36.9615 57.2666L41.4538 52.829L36.9781 48.3935Z"
            fill="#60A51A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.0116 30.6284L36.995 39.5015L41.4872 35.0734L37.0116 30.6284Z"
            fill="#60A51A"
          />
        </g>
        <path
          d="M23.5016 18.9412L27.6294 27.9658C26.4013 28.7662 24.9675 29.1936 23.5016 29.1962V18.9412Z"
          fill="#D8D8FC"
        />
      </g>
    </svg>
  );
}
