import { forwardRef } from 'react'

const Container = forwardRef(({ children, size, spacing, className = '' }, ref) => {
  const _size = size ?? 'normal'
  const _spacing = spacing ?? 'px-2 md:px-4'

  const sizeClass = {
    small: 'w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 mx-auto',
    normal: 'w-full px-6 md:w-full md:px-5 xl:w-9/12 2xl:w-9/12 mx-auto',
    large: 'w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto',
    full: 'w-full max-w-full'
  }

  return (
    <div ref={ref} className={`container sz-${_size} ${sizeClass[_size]} ${_spacing} ${className}`}>
      { children }
    </div>
  )
})

Container.displayName = "Container"

export default Container