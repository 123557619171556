export default function ChevronDownMenu({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.22087 4.8846C2.51536 4.59391 2.99283 4.59391 3.28733 4.8846L8.01692 9.55297L12.7301 5.19152C13.034 4.91037 13.5112 4.92556 13.796 5.22546C14.0809 5.52537 14.0655 5.99641 13.7617 6.27757L8.51576 11.1319C8.21883 11.4067 7.75457 11.3993 7.46677 11.1152L2.22087 5.93725C1.92638 5.64657 1.92638 5.17528 2.22087 4.8846Z"
      />
    </svg>
  );
}
